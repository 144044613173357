<style lang="scss">


</style>
<template>
<div class="card-leaderboard" :class="loading ? 'whirl' : ''" style="min-height: 100px;">
    <b-row class="mt-1" v-if="rankings">
      <b-col lg="12" class="rank-boxes-small animated-fast fadeInLeft" style="animation-delay: 0.6s">
        <div class="px-3">

          <b-row class="py-1 align-content-center" v-for="(position, key , index) in rankings" :key="position.name"
                 :class="{'bg-primary': position.winner}">
            <b-col cols="2" class="d-flex align-items-center justify-content-center position">
              {{ index + 1 }}.
            </b-col>
            <b-col cols="2" class="d-flex align-items-center">
              <div class="user-block-status">
                <img class="w-100" :src="position.avatar_url" alt="Avatar">
                <AvatarMask class="avatar-mask"/>
              </div>
            </b-col>
            <b-col class="d-flex align-items-center">
              {{ position.name }}
            </b-col>
            <b-col cols="3" class="d-flex align-items-center justify-content-end text-right" style="font-size: 0.8em">
              <div>
                <div class=text-bold>
                  {{ position.score }} <i class="ml-2 fad fa-shopping-cart"></i>
                </div>
                <div>
                 {{ position.price | currency }} EUR
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <template v-if="rankings && rank > 10 && score > 0">

      <div class="text-left mt-3">
      {{ $t('affiliate.component.leaderboard.your_rank')}}:
      </div>

      <b-row class="mt-1" >
        <b-col lg="12" class="rank-boxes-small animated-fast fadeInLeft text-bold" style="animation-delay: 0.6s">
          <div class="px-3">
            <b-row class="py-1 align-content-center">
              <b-col cols="2" class="d-flex align-items-center justify-content-center position">
                {{ rank }}.
              </b-col>
              <b-col cols="2" class="d-flex align-items-center">
                <div class="user-block-status">
                  <img class="w-100" :src="$store.getters.user('avatar_url')" alt="Avatar">
                  <AvatarMask class="avatar-mask"/>
                </div>
              </b-col>
              <b-col class="d-flex align-items-center">
                {{ $store.getters.user('name') }}
              </b-col>
              <b-col cols="3" class="d-flex align-items-center justify-content-end text-right" style="font-size: 0.8em">
                <div>
                  <div class=text-bold>
                    {{ score }} <i class="ml-2 fad fa-shopping-cart"></i>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>

import AvatarMask from '@/../public/img/hexagon-mask.svg?inline';

export default {
  props: ['type', 'name'],
  components: {
    AvatarMask
  },
  data() {
    return {
      rankings: [],
      rank: 0,
      score: 0,
      rewards: [],
      loading: true
    }
  },
  mounted() {
    this.$api.get('ranking/leaderboard/' + this.name).then((response) => {

      this.rewards = response.data.prices;
      this.rank = response.data.rank;
      this.score = response.data.score;
      this.rankings = response.data.leaderboard || [];
      this.loading = false;
    });
  },
  methods: {},
}
</script>