<template>
    <ContentWrapper>
        <breadcrumb :name="$t('affiliate.view.leaderboard.title')"/>

        <div class="container">

            <div class="text-center mb-3" style="font-size: 1.4em">

                <div class="card card-default">
                    <div class="card-body">
                     {{  $t('affiliate.view.leaderboard.subtitle') }}
                    </div>
                </div>

            </div>

            {{ $t('affiliate.view.leaderboard.pool1.title') }}
            <leaderboard :name="'pool_december_firstliner'"></leaderboard>

        </div>
    </ContentWrapper>
</template>
<script>
import Leaderboard from './../components/Leaderboard';

export default {
    components: {
        Leaderboard
    },
    data() {
        return {
            tab: 'nov'
        }
    },
    methods: {
        changeTab(tab) {
            this.tab = tab;
        }
    }
}
</script>